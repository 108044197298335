footer {
  padding: 2em 4em;
  display: flex;
  flex-direction: column;

  .footer-socials {
    display: flex;
    justify-content: center;

    a {
      margin: 0 1em;
    }
  }

  h5 {
    align-self: center;
    font-weight: normal;
    font-size: 0.75em;

    span {
      color: #bf46ea;
      font-weight: bold;
    }
  }
}
