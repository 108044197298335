section {
  padding: 4em 2em;
  // height: 100vh;
  margin-top: -5px;
  text-align: center;
  color: #686868;
  background-color: white;
}

.projects {
  h1 {
    font-size: 2.5em;
    border-bottom: solid #686868 2px;
    letter-spacing: 0.1em;
  }
}

.p1,
.p2 {
  h5 {
    font-size: 1.2em;
    font-weight: normal;
  }
  h3 {
    cursor: pointer;
    font-size: 1.5em;
    letter-spacing: 0.3em;
    color: #bf46ea;
  }
  p {
    font-size: 0.9em;
    font-weight: normal;
  }
  img {
    margin-bottom: 4em;
    cursor: pointer;
  }
}

@media only screen and (min-width: 800px) {
  .p1,
  .p2 {
    text-align: left;
    display: grid;
    grid-template-columns: 45% auto;
  }

  .left {
    margin: 0 2em;
  }
}

@media only screen and (min-width: 1250px) {
  section {
    padding: 4em 10em;
  }
}

@media only screen and (min-width: 1550px) {
  section {
    padding: 4em 20em;
  }
}
