.container {
  position: relative;
  max-width: 80vw;
  max-height: 70vh;
  background-color: #e0d8f1;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #686868;
  font-size: 0.7em;

  img {
    padding: 1.5em;
    border-radius: 50px;
    height: 250px;
    max-width: 600px;
  }

  h3 {
    color: #bf46ea;
    letter-spacing: 0.5em;
  }

  ul {
    list-style: unset;
    line-height: 2em;
    padding: 0 2em;
  }
  a {
    color: #1d91fc;
  }
  .video {
    padding: 0;
    height: 1000px;
  }

  .links {
    align-self: flex-start;
  }
  .fa-times-circle {
    color: white;
    font-size: 2.5em;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    background-color: #686868;
    border-radius: 50%;
  }
}

@media only screen and (min-width: 1050px) {
  .container {
    max-width: 80vw;
    max-height: 80vh;
    font-size: 0.85em;
    h3 {
      font-size: 1.5em;
    }
    ul {
      padding: 0em 8em;
    }
  }
  .links {
    a {
      padding: 0em 8em;
    }
  }
}
