header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 2em;

  .logo {
    color: #1d91fc;
    font-weight: bold;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7em;

    a {
      padding: 0 1em;
      color: #686868;
      cursor: pointer;
    }
  }
}

.socials {
  display: none;
}

@media only screen and (min-width: 800px) {
  header {
    padding: 2em 4em;
  }
  .logo {
    font-size: 1.5em;
  }
  ul {
    // font-size: 2em;
    a {
      font-size: 1.5em;
    }
  }
}

@media only screen and (min-width: 1050px) {
  header {
    justify-content: space-between;
    padding: 2em 4em 2em 4em;

    .socials {
      display: unset;

      a {
        margin: 0 1em;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  header {
    padding: 2em 10em 2em 10em;
  }
}

@media only screen and (min-width: 1550px) {
  header {
    padding: 2em 20em;
  }
}
