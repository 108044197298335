.about {
  padding: 4em 2em;
  // height: 100vh;
  text-align: center;
  color: #686868;
  h1 {
    font-size: 2.5em;
    border-bottom: solid #686868 2px;
    letter-spacing: 0.1em;
  }

  h6 {
    font-size: 1.5em;
    letter-spacing: 0.125em;
  }
  p {
    font-size: 0.9em;
    line-height: 1.5em;
  }
  span {
    font-weight: bold;
  }
}

@media only screen and (min-width: 800px) {
  .about-content {
    display: grid;
    grid-template-columns: 45% auto;
    text-align: left;
  }

  .about-image {
    display: grid;
    margin-right: 1.5em;
    place-content: center;
  }
  .about-paragraph {
    h6 {
      margin: 0;
    }
  }
}

@media only screen and (min-width: 1050px) {
  .about {
    height: 90vh;
  }
  .about-paragraph {
    p {
      line-height: 2em;
    }
  }
}

@media only screen and (min-width: 1250px) {
  .about {
    padding: 4em 10em;
  }
}
@media only screen and (min-width: 1550px) {
  .about {
    padding: 4em 20em;
  }
}
