.hero {
  display: flex;
  // height: 100vh;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 4em;

  h4 {
    color: #686868;
    letter-spacing: 0.175em;
  }
  h1 {
    font-size: 2.5em;
    color: #bf46ea;
    font: bold;
    line-height: 1.5em;
  }
  button {
    border: none;
    border-radius: 2em;
    padding: 1em 2em;
    background-color: #1d91fc;
    color: #ffffff;
    margin-top: 1em;
    cursor: pointer;
  }
}
.right-content {
  display: none;
}

@media only screen and (min-width: 1050px) {
  .hero {
    display: grid;
    grid-template-columns: 50% auto;
    // flex-direction: row;
    // justify-content: space-between;
    height: 90vh;
    text-align: left;
    padding: 0;
  }
  .right-content {
    padding: 6em 4em 6em 4em;
    display: unset;
  }
  .left-content {
    padding: 6em 2em 6em 4em;
    margin-top: 3em;
    h1 {
      font-size: 3.5em;
    }
  }
}

@media only screen and (min-width: 1250px) {
  .right-content {
    padding: 6em 10em 6em 4em;
    display: unset;
  }

  .left-content {
    padding: 6em 2em 6em 10em;
  }
}
@media only screen and (min-width: 1550px) {
  .right-content {
    padding: 6em 20em 6em 4em;
    display: unset;
  }

  .left-content {
    padding: 6em 2em 6em 20em;
  }
}
