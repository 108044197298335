.contact {
  padding: 4em 2em;
  color: #686868;
  text-align: center;
  background-color: white;
  height: 75vh;

  h1 {
    font-size: 2.5em;
    border-bottom: solid #686868 2px;
    letter-spacing: 0.1em;
  }
}

.contact-form {
  margin: 0 auto;
  max-width: 500px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: #e0d8f1;

  input,
  textarea {
    color: white;
    background-color: #686868;
    padding: 0.5em;
    margin: 0.5em 0.5em;
  }
  ::placeholder {
    color: white;
  }

  button {
    padding: 1em;
    letter-spacing: 2px;
    border-radius: 10px;
    margin: 0.5em 0.5em;
    align-self: flex-end;
    background-color: #1d91fc;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1250px) {
  .contact {
    padding: 4em 10em;
  }
}
@media only screen and (min-width: 1550px) {
  .contact {
    padding: 4em 20em;
  }
}
